import axios from 'axios';

export const httpClient = axios.create({
  // baseURL: process.env.BASE_API,
  timeout: 10000,
});

httpClient.interceptors.request.use(
  (config) => {
    return config;
  },
);

httpClient.interceptors.response.use(
  (response) => {
    return response.data;
  },
);
